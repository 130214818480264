.App {
    margin-inline: 20px;
}

.App>div {
    margin-top: -4.8rem;
}

.hero-text {
    position: relative !important;
    animation: fadeInDown 2s ease-in-out;
}

.orange-section {
    background: var(--theme-bright-orange) url('/public/assets/Wave.svg') no-repeat center;
    background-size: cover;
    color: white;
}

.honeyComb-section {
    background: url('/public/assets/HoneyCombBg.jpg') no-repeat center;
    background-size: cover;
}

/* NavBar classes */
.nav-link {
    padding: 4px 16px;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
}

@media screen and (max-width: 768px) {
    .App>div {
        margin-top: -7.8rem;
    }

    .section-container {
        padding-inline: 36px !important;
    }

    .home,
    .about,
    .tenie-ds,
    .tenie-alm,
    .tenie-ALM,
    .contact {
        margin-top: 3rem !important;
    }

    .about {
        margin-top: 1.5rem !important;
    }

    .nav-link {
        border-radius: 0px !important;
    }

    .oi {
        padding-inline: 0px !important;
    }

    .get-in-touch-form {
        column-gap: 24px !important;
    }

    .left-container {
        padding-inline: 0px !important;
    }

    .right-section {
        padding-inline: 32px !important
    }

    .alm-s3>div {
        grid-template-columns: 1fr;
    }

    .alm-s3 .img-container {
        height: 320px !important;
    }

    .content {
        padding: 32px !important;
    }

    .ds-hero .hero-text,
    .alm-hero .hero-text,
    .EDM-hero .hero-text {
        bottom: 0;
        left: 0px;
    }

    .hero-text {
        position: absolute !important;
        animation: heroFadeInDown 2s ease-in forwards !important;
    }

    .adminblogscontainer,
    .admincontactcontainer,
    .adminuserscontainer,
    .blogDetailNew,
    .blogs,
    .createBlog,
    .editBlog,
    .userBlogs {
        margin-top: 1rem !important;
    }
}

@media screen and (max-width: 640px) {
    .App>div {
        margin-top: -5.5rem;
    }

    .ds-s3 .s3-img-container {
        /* background-image: url('/public/assets/Products/DS/FreeTrialMobile.jpg') !important; */
    }

    .alm-s3 .s3-img-container {
        /* background-image: url('/public/assets/Products/ALM/FreeTrialMobile.jpg') !important; */
    }
}

.nav-link:hover {
    border-top: 3px solid var(--theme-bright-orange);
}

.nav-link:nth-of-type(1):hover {
    border-radius: 0.75rem 0 0.75rem 0.75rem;
}

.nav-dropdown {
    position: absolute;
    width: max-content;
    z-index: 1;
    display: none !important;
    bottom: 0;
    transform: translateY(100%);
    left: 20px;
    color: white;
    background-color: var(--theme-blue);
}

.products:hover .nav-dropdown,
.admin-link:hover .nav-dropdown,
.blogs-link:hover .nav-dropdown {
    display: block !important;
}

.nav-dropdown-item {
    padding: 8px;
    border-bottom: 1px solid #b3b3b3;
    transition: all 300ms ease-in-out;
}

.nav-dropdown-item:hover {
    background-color: var(--theme-bright-orange);
}

/* animation classes */
.orange-ani,
.gear-ani,
.pricing-ani {
    opacity: 0;
    position: relative;
}

.gear-ani:nth-child(1) {
    animation: fade 1.25s ease-in-out 0s forwards;
}

.gear-ani:nth-child(3) {
    animation: fade 1.25s ease-in-out 1.25s forwards;
}

.gear-ani:nth-child(4) {
    animation: fade 1.25s ease-in-out 2.5s forwards;
}

.gear-ani:nth-child(5) {
    animation: fade 1.25s ease-in-out 3.75s forwards;
}

.orange-ani:nth-child(1),
.pricing-ani:nth-child(1) {
    animation: ltr 2s ease-out 0s forwards;
}

.orange-ani:nth-child(3),
.pricing-ani:nth-child(2) {
    animation: rtl 2s ease-out 2s forwards;
}

.orange-ani:nth-child(4) {
    animation: ltr 2s ease-out 4s forwards;
}

.orange-ani:nth-child(5) {
    animation: rtl 2s ease-out 6s forwards;
}

/* Home Page classes */
.home .hero-section {
    background: url('/public/assets/Home/HeroBg.jpg') no-repeat center/cover;
}

/* About Page classes */
.about * {
    font-family: 'Montserrat', sans-serif !important;
}

.about .hero-section {
    background: url('/public/assets/About/HeroBg.svg') no-repeat center/cover;
}

.about-features {
    background: url('/public/assets/About/Group.svg') no-repeat;
    background-position: -15px;
    background-size: calc(75% + 52.5px);
}

.about-content:nth-of-type(2n) {
    flex-direction: row !important;
    margin-left: auto;
    text-align: left !important;
}

.about-item {
    position: relative;
    opacity: 0;
}

.about-item:nth-child(2) {
    animation: fadeInDown 2s ease-out 4s forwards;
}

.about-item:nth-child(4) {
    animation: fadeInDown 2s ease-out 6s forwards;
}

.about-item:nth-child(5) {
    animation: fadeInUp 2s ease-out 0s forwards;
}

.about-item:nth-child(7) {
    animation: fadeInUp 2s ease-out 2s forwards;
}

.about-content:nth-child(1) {
    animation: ltr 2s ease-out 0s forwards;
}

.about-content:nth-child(2) {
    animation: rtl 2s ease-out 2s forwards;
}

.about-content:nth-child(3) {
    animation: ltr 2s ease-out 4s forwards;
}

.about-content:nth-child(4) {
    animation: rtl 2s ease-out 6s forwards;
}


/* Products classes */
/* Tenie PDM */
.tenie-ds .hero-section {
    background: url('/public/assets/Products/DS/HeroBg.svg') no-repeat center/cover;
}

.ds-benefit {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Tenie ALM */
.tenie-alm .hero-section {
    background: url('/public/assets/Products/ALM/HeroBg.svg') no-repeat center/cover;
}

/* Tenie EDM */
.tenie-EDM .hero-section {
    background: url('/public/assets/Products/EDM/HeroBg.svg') no-repeat center;
    background-size: cover;
}

.coming-soon {
    opacity: 0;
    animation: fade 2s ease-out 2s forwards;
}

/* Contact Page classes */
.contact-hero-section {
    background: url('/public/assets/Contact/ContactHero.jpg') no-repeat center;
    background-size: cover;
}

.contact-form-container label {
    font-weight: 500;
}

.contact-form-container span {
    margin-inline: 4px;
}

.error-message {
    margin-block: -18px 8px;
    font-weight: 400;
    color: black;
}

.contact-info {
    background: #EDEDED url('/public/assets/Contact/contactright.png') no-repeat center;
    background-size: cover;
}

.contact-input {
    color: white !important;
    border: 1px solid white;
    border-radius: 8px;
    background-color: transparent;
    padding: 6px 8px;
    margin: 4px 0 20px 0;
    width: 90%;
}

.cb {
    width: initial;
}

/* PrivacyPolicy */
.privacy-policy-section .hero {
    background: url('/public/assets/privacyPolicyImage.svg') no-repeat center/cover;
}

.privacy-policy-section strong {
    color: var(--theme-blue);
}

/* Footer Classes */
footer .left-section {
    background: var(--theme-footer-gray) url('/public/assets/FooterWave.png') no-repeat center;
    background-size: cover;
}

.get-in-touch-form input,
textarea {
    width: 100%;
    margin-block: 8px;
    border-radius: 8px;
    padding: 1px 8px;
    color: #000 !important;
    resize: none !important;
}

.send-btn {
    background: #272727 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
}

/*  */
.adminblogscontainer,
.admincontactcontainer,
.adminuserscontainer,
.blogDetailNew,
.blogs,
.createBlog,
.editBlog,
.userBlogs {
    margin-top: 5.7rem !important;
}