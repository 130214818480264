.first-blog {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.first-blog-content {
    height: 80%;
    width: 400px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 4;
}

.first-blog-banner-image {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.first-blog-category {
    color: black;
    font-weight: 600;
    font-size: 13px;
    background-color: #D9D9D9;
    padding: 5px 30px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.first-blog-title {
    font-size: 18px;
    line-height: 27px;
    color: white;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.first-blog-body {}

.first-blog-readMore {
    color: white;
    font-size: 16px;
    background-color: var(--theme-blue);
    padding: 10px 20px;
    border: 0;
}

.first-blog-readMore:hover {
    background: var(--theme-bright-orange);
}

.categories {
    border-bottom: 5px solid #D9D9D9;
}

.categories-name {
    color: var(--theme-blue);
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 30px;
}

.blogs-column {
    padding: 25px 20px 0;
    margin-bottom: 15px;
    line-height: 20px;
}

.blogs-column img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.blog-category {
    background-color: #D9D9D9;
    font-size: 11px;
    padding: 0 8px;
    font-weight: 600;
    margin: 5px 0 10px;
}

.blog-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

.blog-summary {
    font-size: 14px;
    text-align: justify;
    line-height: 17px;
    margin-bottom: 10px;
    font-weight: 500;
}

.readMore {
    padding: 10px 15px;
    background-color: var(--theme-blue);
    color: white;
    font-weight: 500;
    border: 0;
    font-size: 14px;
}

.readMore:hover {
    background: var(--theme-bright-orange);
}

#load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-blue);
    color: #fff;
    font-weight: 400;
    padding: 10px 23px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
}

#load-more:hover {
    background: var(--theme-bright-orange);
}

#load-more img {
    height: 27px;
    width: 27px;
    margin-top: 3px;
}