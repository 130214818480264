@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--theme-orange: #E4842C;
	--theme-blue: #1CA0C4;
	--theme-light-gray: #F0F0F1;
	--theme-footer-gray: #D3D3D3;
	--theme-bright-orange: #F88C2A;
	--theme-orange-hover: #faa352;
	--theme-blue-hover: #4dc4e6;
}

* {
	font-family: 'Montserrat', sans-serif;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow: hidden scroll;
}

/* Global classes */
.hero-heading {
	font-size: 28px;
	font-weight: 500;
}

.know-more {
	background: var(--theme-blue);
	color: white;
	font-size: 16px;
	font-weight: 400;
}

.section-container {
	padding: 36px 64px;
}

.section-heading {
	font-size: 22px;
	font-weight: 500;
	color: black;
	padding-block: 8px;
	width: 210px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 60px;
	display: inline-block;
	border: 1px solid var(--theme-bright-orange);
}

.crumb {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 60px;
}

.sub-heading {
	font-size: 16px;
	font-weight: 600;
}

.sub-heading-content {
	font-weight: 400;
	font-size: 14px;
}

/* animations */
@keyframes ltr {
	0% {
		left: -100px;
		opacity: 0;
	}

	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes rtl {
	0% {
		left: 100px;
		opacity: 0;
	}

	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes fadeInDown {
	0% {
		top: -50px;
		opacity: 0;
	}

	100% {
		top: 0px;
		opacity: 1;
	}
}

@keyframes fadeInUp {
	0% {
		bottom: -50px;
		opacity: 0;
	}

	100% {
		bottom: 0px;
		opacity: 1;
	}
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes heroFadeInDown {
	0% {
		opacity: 0;
		transform: scale(0.45) translate(-70%, 40%);
	}

	100% {
		opacity: 1;
		transform: scale(0.45) translate(-70%, 100%);
	}
}

.fadeInDown {
	position: relative !important;
	animation: fadeInDown 2s ease-in-out;
}

.fade {
	opacity: 0;
	animation: fade 3.5s ease-in-out forwards;
}