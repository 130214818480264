.adminblogscontainer {
    min-height: 90vh;
    padding: 0 130px 50px;
}


.blogs-headings {
    background-color: #E4E3E3;
    text-align: center;
    padding: 7px 0;
    border-right: 2.3px solid #cfcece;
    font-size: 16px;
}

.blog-info {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fbfafa;
    border-right: 2.3px solid #cfcece;
    border-bottom: 2.3px solid #cfcece;
    font-size: 14px;
    padding: 6px 10px;
}

.blog-btn {
    border: 0;
    padding: 5px 15px;
    border-radius: 20px;
    color: white;
    width: 120px
}

.blog-ngt-btn {
    background-color: var(--theme-bright-orange);
}

.blog-ngt-btn:hover {
    background-color: var(--theme-orange-hover);
}

.blog-pst-btn {
    background-color: var(--theme-blue);
    text-align: center;
    text-decoration: none;
    color: white;
}

.blog-pst-btn:hover {
    text-decoration: none;
    color: white;
    background-color: var(--theme-blue-hover);
}

@media (max-width:800px) {
    .adminblogscontainer {
        width: 1200px;
        padding: 0 0 150px;
    }
}