.flash {
    border-radius: 0 !important;
    width: 100%;
    color: white !important;
    height: 50px;
    display: flex !important;
    padding: 0 20px !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 20px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 24px;
    height: 50px;
    z-index: 100;
}

.flash-success {
    background-color: var(--theme-bright-orange) !important;
}

.flash-error {
    background-color: var(--theme-blue) !important;
}

.flash-btn {
    position: none !important;
    padding: 0 !important;
    position: unset !important;
    background: none !important;
    opacity: 1 !important;
    display: flex !important;
    align-items: center !important;
    height: 20px !important;
}