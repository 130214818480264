.register-header {
    height: 10px;
    background-color: var(--theme-bright-orange);
}

.casewell-logo-login {
    height: 80px;
    margin-bottom: 70px;
}

.register-section {
    font-size: 16px;
}

.register-field-input::-webkit-input-placeholder {
    font-size: 14px;
}

.register-field-label {
    padding-bottom: 5px;
}

.register-field-input {
    width: 250px;
    padding: 15px 65px 15px 20px;
    border: 2px solid #e4e4e474;
    outline: 0;
    background: #F5F5F5;
    border-radius: 10px;
    color: #838383;
}

.register-btn {
    border: 0;
    padding: 15px 35px;
    color: white;
    background: var(--theme-blue) !important;
    border-radius: 10px;
}

.register-btn:hover {
    background: var(--theme-bright-orange) !important;
}

.register-footer {
    width: 100%;
}


.login-link {
    color: var(--theme-blue);
    text-decoration: none;
    font-weight: 600;
}

.login-link:hover {
    color: var(--theme-bright-orange);
    text-decoration: underline;
}